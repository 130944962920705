// ---IMPORT JS
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Particles from 'particles.vue3'
import mitt from 'mitt'
import { OhVueIcon, addIcons } from 'oh-vue-icons'
import { BiActivity } from 'oh-vue-icons/icons'

import './utils/vee-validate'
// ---IMPORT CSS
import './index.css'
// import './assets/css/tailwind.css'

// ---CONFIG
// * https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + '/api/v1'

// * https://oh-vue-icons.js.org/
addIcons(
  BiActivity
)

const app = createApp(App)
// --Global Vars
// app.config.globalProperties.projectName = 'Promotica Control Panel'

app
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(Particles)
  .component('v-icon', OhVueIcon)

const emitter = mitt()
app.config.globalProperties.emitter = emitter

app
  .mount('#app') // ---FIRED UP, AND READY TO SERVE 🤖

// ---DEBUG .env
console.log(process.env.VUE_APP_NAME + ' - ' + process.env.NODE_ENV)
