import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import NotFound from '@/views/404.vue'

const routes = [
  {
    path: '/',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  },
  // {
  //   path: '/confirm',
  //   component: () => import('@/views/Confirm.vue')
  // },
  // {
  //   path: '/reset',
  //   component: () => import('@/views/Reset.vue')
  // },
  // {
  //   path: '/recovery',
  //   component: () => import('@/views/Recovery.vue')
  // },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('initializeStore')
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    '/login'
  ]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = store.state.user

  // console.log('I AM THE ROUTER GUARD', authRequired, loggedIn)
  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next()
})
export default router
